.fv:focus-visible {
  outline: auto;
}

.skip-link {
  position: absolute;
  top: 0;
  left: 0;
  background: #0078d4;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 0px;
  text-decoration: none;
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.skip-link:focus {
  transform: translateY(0);
}

.skip-link:hover {
  color: black;
}

.asa-btn:focus-visible {
  border: 2px dashed black !important;
  outline: 2px dashed white !important;
}



:focus-visible:not([aria-hidden="true"]) {
  outline: 2px solid white !important;
  /* White outline */
  border: 2px solid black !important;
  /* Black border */
  outline-offset: 2px;
  /* Space between border and outline */
  transition: none !important;
}


.ant-btn:focus-visible {
  outline: 2px solid white !important;
  border: 2px solid black !important;
  outline-offset: 2px;
  transition: none !important;
}

/* Specific to AntD inputs */
.ant-input:focus-visible {
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 2px;
  transition: none !important;
  box-shadow: inset 0 0 0 2px black !important;
}




/* Specific to AntD select dropdowns */
.ant-select-focused .ant-select-selector {
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 0px;
  transition: none !important;
  box-shadow: inset 0 0 0 2px black !important;
}

/* Specific to AntD checkboxes */
.ant-checkbox-input:focus-visible .ant-checkbox {
  box-shadow: inset 0 0 0 2px black !important;
  outline: 2px solid white !important;
  border: 2px solid black !important;
  outline-offset: 2px;
  transition: none !important;
}

/* Specific to AntD radio buttons */
.ant-radio-wrapper:focus-visible .ant-radio {
  outline: 2px solid white !important;
  border: 2px solid black !important;
  outline-offset: 2px;
  transition: none !important;
}

.asa-link:focus-visible {
  outline: none !important;
  border: none black !important;
  text-decoration: underline;
}


.ant-tabs-tab:hover {
  color: #bd5800;
}

.ant-pagination-item a:hover {
  color: #bd5800;
}

.ant-pagination-item-active:hover {
  border-color: #bd5800;
}

.filterTag:hover {
  color: #bd5800;
  border-color: #bd5800;
}

.ant-radio-button-wrapper:hover {
  color: #bd5800;
  border-color: #bd5800;
}


.asa-author:hover {
  color: #bd5800 !important;
}

.asa-anchor:hover{
  color: #bd5800 !important;
}

.asa-danger:hover{
  color: #000 !important;
}

.asa-danger-btn:hover{
  color: #fff !important;
  background-color: #e70d3d !important;
  border: 1px solid #e70d3d !important;
}

.asa-select .ant-select-selector:hover{
  border: 1px solid #bd5800 !important;
  color: #bd5800;
  transition: none;
}

.ant-pagination-options .ant-select-selector:hover{
  border: 1px solid #bd5800 !important;
  color: #bd5800;
  transition: none;
}

.asa-card {
  opacity: 1;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.asa-card:hover {
  opacity: 0.7;
  transition: opacity 0.3s ease-out;
}

.ant-input-group-addon:hover {
  box-shadow: inset 0 0 0 2px black !important;
  transition: none !important;
}

.asa-search input {
  border: none !important;
}

.asa-search .ant-input-search-button:focus-visible {
  box-shadow: inset 0 0 0 2px black !important;
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 0px;
  transition: none !important;
}

.ant-menu-submenu-title:focus-visible {
  box-shadow: inset 0 0 0 2px black !important;
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 0px;
}



.ant-menu-item:focus-visible {
  box-shadow: inset 0 0 0 2px black !important;
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 0px;
  transition: none !important;
}

.ant-checkbox-input:focus-visible + .ant-checkbox-inner {
  box-shadow: inset 0 0 0 2px black !important;
}

.ant-select .ant-select-selection-item:focus-visible, .ant-select .ant-select-selection-search:focus-visible, .ant-select .ant-select-selection-search input:focus-visible{
  outline: 0px solid white !important;
  border: 0px solid black !important;
  outline-offset: 0px;
  transition: none !important;
}

.bm-component a:has(img) {
  display: inline-block;
}